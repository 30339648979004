// Useful: https://phrase.com/blog/posts/nuxt-js-tutorial-i18n/#using-custom-date-formats
import datetimeFormats from './locales/datetimeFormats'
import en from './locales/en.json'
import nl from './locales/nl.json'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'nl',
  availableLocales: ['en', 'nl'],
  fallbackLocale: 'nl',
  strategy: 'no_prefix',
  detectBrowserLanguage: {
    useCookie: true,
    cookieKey: 'i18n_redirected',
    redirectOn: 'root', // recommended
  },
  messages: {
    en,
    nl,
  },
  datetimeFormats,
}))
